import React from "react";
import { Tab, Nav } from 'react-bootstrap';
import { Footer } from "../../../components/common/common";
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

export default function Account() {
    const history = useHistory();
    const profileState = useSelector((state) => state.profile);
    function logoutHandler() {
        localStorage.removeItem("jwttoken");
        window.location.reload();
    }
    return (
        <div className="screen">
            <div className="main-content bg-light">
                <span className="font-m fw-semibold">Account</span>
                <div className="text-center ei-logo">
                    <span className="font-l">{(profileState.firstname) ? (profileState.firstname[0] + profileState.lastname[0]) : ""}</span>
                </div>
                <div className="account-title bg-white mb-3 text-center">
                    <h4 className="font-m fw-semibold mb-0 mt-4">{(profileState.firstname) ? profileState.firstname + " " + profileState.lastname : ""}</h4>
                    <span className="font-s">I.D: {(profileState.username) ? profileState.username : ""}</span>
                </div>
                <ul className="account-lists">
                    <li onClick={() => { history.push("/account-profile") }}>
                        <span className="d-flex align-items-center"><img src="images/account-profile.svg" alt="account-profile-icon" className="me-2" />Profile</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li onClick={() => { history.push("/account-transaction") }}>
                        <span className="d-flex align-items-center"><img src="images/transection-history.svg" alt="transection-history-icon" className="me-2" />Transaction History</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li onClick={() => { history.push("/account-change-password") }}>
                        <span className="d-flex align-items-center"><img src="images/password.svg" alt="Password-icon" className="me-2" />Change Password</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li onClick={() => { history.push("/account-rejection-logs") }}>
                        <span className="d-flex align-items-center"><img src="images/rejection-logs.svg" alt="rejection-logs-icon" className="me-2" />Rejection logs</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li onClick={() => { history.push("/account-ledger-master") }}>
                        <span className="d-flex align-items-center"><img src="images/ledger-mast.svg" alt="ledger-mast-icon" className="me-2" />Ledger Master</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li onClick={() => { history.push("/bill-history") }}>
                        <span className="d-flex align-items-center"><img src="images/bill-history.svg" alt="bill-history-icon" className="me-2" />Bill History</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    {/* <li>
                        <span className="d-flex align-items-center"><img src="images/gift.svg" alt="gift-icon" className="me-2" />7 Level Refreal Program</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/leader-board.svg" alt="leader-board-icon" className="me-2" />Leader board</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li> */}
                    {/* <li>
                        <span className="d-flex align-items-center"><img src="images/about-us.svg" alt="about-us-icon" className="me-2" />About Us</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/terms-and-condition.svg" alt="terms-and-condition-icon" className="me-2" />Terms and Conditions</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li>
                    <li>
                        <span className="d-flex align-items-center"><img src="images/help.svg" alt="help-icon" className="me-2" />Help</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li> */}
                    {/* <li>
                        <span className="d-flex align-items-center"><img src="images/theme.svg" alt="theme-icon" className="me-2" />Theme</span>
                        <span><img src="images/account-right-arrow.svg" /></span>
                    </li> */}
                </ul>
                <div class="mt-3"><button type="button" class="signin-btn w-100 btn btn-primary" onClick={logoutHandler}><span className="d-flex align-items-center justify-content-center"><img src="images/log-out.svg" alt="logout-icon" className="me-1"></img>Logout</span></button></div>
            </div>
            <Footer />
        </div>
    );
};

