import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_PASSWORD_URL } from "../../../helper/url_helper";
import api from "../../../helper/api_helper";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';

const AccountChangePassword = () => {
    const history = useHistory();
    const loginState = useSelector((state) => state.login);
    const [showPasswords, setShowPasswords] = useState([false, false, false]);
    const [passwords, setPasswords] = useState(["", "", ""]);

    function setPasswordHandler(value, index) {
        let passwordsTemp = JSON.parse(JSON.stringify(passwords));
        passwordsTemp[index] = value;
        setPasswords(passwordsTemp);
    }

    function togglePassword(index) {
        let showPasswordsTemp = JSON.parse(JSON.stringify(showPasswords));
        showPasswordsTemp[index] = (!showPasswordsTemp[index]);
        setShowPasswords(showPasswordsTemp);
    }

    async function submit() {
        try {
            if (!passwords[0]) {
                toast.error("Please enter old password");
                return;
            }
            if (passwords[1] !== passwords[2]) {
                toast.error("Confirm password not match");
                return;
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let data = {
                "old_password": passwords[0],
                "password": passwords[1]
            };
            let res = await api.post(CHANGE_PASSWORD_URL, data);
            if (res.data.status === "SUCCESS") {
                toast.success("Password changed!");
                // history.goBack();
            }
            else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    }

    return (
        <div className="screen">
            <div className="main-content bg-light">
                <div className="d-flex justify-content-center flex-column">
                    <div className="mb-30" >
                        <span className="d-flex align-items-center" onClick={() => { history.goBack() }}><img className="me-2" src="images/left-arrow.svg" alt="left-arrow" />Change Password</span>
                    </div>
                    <div className="form-wrapper">
                        <Form>
                            <Form.Group className="mb-30" controlId="formGroupPassword">
                                <Form.Label className="custom-label">Current Password</Form.Label>
                                <Form.Control className="custom-form-control" size="lg" type={showPasswords[0] ? "text" : "password"} value={passwords[0]} onChange={(e) => { setPasswordHandler(e.target.value, 0) }} placeholder="***************" />
                                <div class="input-icon" onClick={() => { togglePassword(0) }}><img class="right-icon" src="images/eye.svg" /></div>
                            </Form.Group>
                            <Form.Group className="mb-30" controlId="formGroupPassword">
                                <Form.Label className="custom-label">New Password</Form.Label>
                                <Form.Control className="custom-form-control" size="lg" type={showPasswords[1] ? "text" : "password"} value={passwords[1]} onChange={(e) => { setPasswordHandler(e.target.value, 1) }} placeholder="***************" />
                                <div class="input-icon" onClick={() => { togglePassword(1) }}><img class="right-icon" src="images/eye.svg" /></div>
                            </Form.Group>
                            <Form.Group className="mb-30" controlId="formGroupPassword">
                                <Form.Label className="custom-label">Confirm Password</Form.Label>
                                <Form.Control className="custom-form-control" size="lg" type={showPasswords[2] ? "text" : "password"} value={passwords[2]} onChange={(e) => { setPasswordHandler(e.target.value, 2) }} placeholder="***************" />
                                <div class="input-icon" onClick={() => { togglePassword(2) }}><img class="right-icon" src="images/eye.svg" /></div>
                            </Form.Group>
                            <div className="">
                                <Button className="signin-btn w-100" onClick={submit}>Update Password</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountChangePassword